import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../../util/serializers";

const SimpleBlocks = ({ data, className }) => {
  return (
    <BlockContent
      className={className}
      blocks={data}
      serializers={{
        ...serializers,
        ...{
          container: "div",
        },
      }}
      renderContainerOnSingleChild={true}
    />
  );
};

export default SimpleBlocks;
