import React from "react";
import { Link } from "gatsby";
import Image from "../blocks/image";

const PostSpotlight = ({ post }) => {
  let className = `post-tile post-tile--spotlight`;
  if (post.posterYoutube) {
    className += " post-tile--video";
  }

  return (
    <div className={className}>
      <Link to={`/${post._type}s/${post.slug.current}`} className="wrapper">
        <div className="post-info">
          <h3 className="title">{post.title}</h3>
        </div>
        <Image
          image={post.poster ? post.poster.image : null}
          options={{
            width: 1152,
            aspectRatio: 1,
            placeholder: "blurred",
            fit: "clip",
          }}
          block={true}
        ></Image>
      </Link>
    </div>
  );
};

export default PostSpotlight;
