import React from "react";
import { Link } from "gatsby";
import { parse, format } from "date-fns";
import PostTaxonomy from "./taxonomy";
import Image from "../blocks/image";
import { Article, Event, Resource } from "../../util/icons";
import { white } from "../../util/colors";

const PostTile = ({ post, featured = false, fromLinkUp }) => {
  const width = 370;
  const aspectRatio = featured
    ? {
        w: 1.78,
        h: 1,
      }
    : { w: 1.4, h: 1 };
  const imageOptions = {
    width,
    placeholder: "blurred",
  };

  let date;
  if (post?._type === "event") {
    const d = post?.eventDates?.start || post.date;

    if (d) {
      const parsed = parse(d, "yyyy-MM-dd", new Date());
      date = format(parsed, "d MMM");
    }
  }

  const icons = {
    article: <Article className="icon icon--inline" />,
    event: <Event className="icon icon--inline" />,
    resource: <Resource className="icon icon--inline" />,
  };

  let slug = null;
  if (post.slug) {
    slug = typeof post.slug === "string" ? post.slug : post.slug.current;
  }

  let image = null;
  if (post.poster) {
    // TODO: Fix this hack. Shaping a string into an object.
    image =
      typeof post.poster === "string"
        ? {
            asset: { _id: post.poster },
          }
        : post.poster.image;
  }

  let changedPostType = null;
  if (post._type === "resource") {
    changedPostType = "research";
  }
  if (post._type === "resources") {
    changedPostType = "resources";
  }

  // console.log(post._type + " --> " + changedPostType);

  return (
    <li className={`post-tile${featured ? " post-tile--featured" : ""}`}>
      <div className="wrapper">
        {featured && (
          <div
            className="featured"
            style={{
              "--fill": white,
            }}
          >
            {icons[post._type]}
            Featured {post._type}
          </div>
        )}
        <div aria-hidden={true}>
          <Link
            to={`/${
              !changedPostType ? post._type + "s" : changedPostType
            }/${slug}`}
            className="image"
            tabIndex="-1"
          >
            {/* {console.log(`/${post._type}s/${slug}`)} */}

            <Image
              image={image}
              options={imageOptions}
              rounded={featured}
              aspectRatio={aspectRatio}
            ></Image>
            {date && <span>{date}</span>}
          </Link>
        </div>
        <div className="info">
          <Link
            to={`/${
              !changedPostType ? post._type + "s" : changedPostType
            }/${slug}`}
            className="title"
          >
            <p>{post.title}</p>
          </Link>
          {post.subtitle && <p className="subtitle">{post.subtitle}</p>}
          {fromLinkUp ? "" : <PostTaxonomy post={post} clickable={true} />}
        </div>
      </div>
    </li>
  );
};

export default PostTile;
