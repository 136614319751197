import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import PostsList from "../components/posts/list";

const questions = [
  {
    title: "Would you mind telling me your child’s hearing loss?",
    options: ["Unilateral (one ear)", "Bilateral (two ears)"],
  },
  {
    title: "Do you want to know more about:",
    options: ["Professional services", "Parent mentors", "Early intervention"],
  },
];

function GetStarted() {
  const [formActiveQuestion, setFormActiveQuestion] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [posts, setPosts] = React.useState([]);

  const wrapper = React.createRef();
  const form = React.createRef();

  // Fetch all of the posts needed to generate survey results
  const data = useStaticQuery(graphql`
    query {
      allSanityArticle(
        filter: {
          _id: {
            in: [
              "daedbfc7-1e2b-4994-afb1-0550d5984691"
              "9f2301b5-97e1-4f5c-b6cb-0ed79e678ca5"
              "9653fec9-5b4d-401d-82ab-e482969a44c7"
              "3095a257-34f4-4b93-9120-2f1d7a9942dc"
              "652dbea2-5d19-474f-81f5-ccd194caa7c9"
            ]
          }
        }
      ) {
        nodes {
          ...articleTileFields
        }
      }
    }
  `);

  const allPosts = data.allSanityArticle.nodes;

  const onGetStartedClick = () => {
    trackCustomEvent({
      category: "Get Started",
      action: "Start",
    });
    setFormActiveQuestion(1);
  };

  const getPosts = () => {
    // Manual logic for populating the results

    // First two posts are always the same
    const posts = [
      allPosts.find(p => p._id === "daedbfc7-1e2b-4994-afb1-0550d5984691"),
      allPosts.find(p => p._id === "9f2301b5-97e1-4f5c-b6cb-0ed79e678ca5"),
    ];

    if (answers.length > 1) {
      let searchId;

      // Second answer determines third post
      switch (answers[1]) {
        case "Professional services":
          searchId = "9653fec9-5b4d-401d-82ab-e482969a44c7";
          break;
        case "Parent mentors":
          searchId = "3095a257-34f4-4b93-9120-2f1d7a9942dc";
          break;
        case "Early intervention":
          searchId = "652dbea2-5d19-474f-81f5-ccd194caa7c9";
          break;
        default:
          break;
      }

      searchId && posts.push(allPosts.find(p => p._id === searchId));
    }

    setPosts(posts);
  };

  const onNextQuestionClick = () => {
    trackCustomEvent({
      category: "Get Started",
      action: `Question ${formActiveQuestion} completed`,
    });
    if (formActiveQuestion >= questions.length) {
      getPosts();
    } else {
      setFormActiveQuestion(formActiveQuestion + 1);
    }
  };

  const onStartAgainClick = () => {
    setFormActiveQuestion(null);
    setPosts([]);
    setAnswers([]);
    wrapper.current.scrollIntoView({ block: "start" });
    trackCustomEvent({
      category: "Get Started",
      action: "Restart",
    });
  };

  const onAnswerChange = (question, option) => {
    setAnswers(prevState => {
      const a = [];
      a[question] = option;
      for (const i in prevState) {
        if (parseInt(i, 10) !== parseInt(question, 10)) {
          a[i] = prevState[i];
        }
      }
      return a;
    });
  };

  const image = () => {
    let img;
    switch (formActiveQuestion) {
      case 2:
        img = (
          <div className="get-started-image">
            <img src="/img/platy-mumboy-yoga.png" alt="" />
          </div>
        );
        break;
      case 1:
        img = (
          <div className="get-started-image">
            <img src="/img/platy-mumboy-blocks.png" alt="" />
          </div>
        );
        break;
      default:
        img = (
          <div className="get-started-image hello">
            <img src="/img/platy-mum.gif" alt="" />
          </div>
        );
        break;
    }
    return img;
  };

  const Results = (
    <div className="get-started-results">
      <h2>We've found some great places to start</h2>
      <p>
        Based on your responses we have suggested some good places to start.
      </p>
      <PostsList
        posts={posts}
        includeFeatured={false}
        condensed={false}
        carousel={true}
      ></PostsList>
      <button
        className="button button--large -dosis"
        onClick={onStartAgainClick}
      >
        Start again
      </button>
    </div>
  );

  const Form = (
    <>
      <div className="get-started-form">
        {formActiveQuestion ? (
          <form ref={form}>
            {questions.map((question, i) => {
              return (
                <div
                  className={`question ${
                    formActiveQuestion === i + 1 ? "-active" : ""
                  }`}
                  key={i}
                >
                  <h3>{question.title}</h3>
                  {question.description && <p>{question.description}</p>}
                  <div className="selection">
                    {question.options.map((option, j) => {
                      return (
                        <div className="select" key={j}>
                          <input
                            type="radio"
                            name={`q${i}`}
                            id={`q${i}${j}`}
                            checked={answers[i] === option}
                            onChange={() => onAnswerChange(i, option)}
                            value={option}
                          />
                          <label
                            htmlFor={`q${i}${j}`}
                            className="button button--block button--large -dosis"
                          >
                            {option}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <button
              className="button button--block button--large -dosis -next"
              onClick={onNextQuestionClick}
              type="button"
              disabled={
                answers.length < formActiveQuestion ||
                answers[formActiveQuestion - 1] === null
              }
            >
              Next
            </button>
          </form>
        ) : (
          <div className="get-started-intro">
            <h2>Not sure where to start?</h2>
            <p>
              Answer a few questions to see some great information to get you
              started.
            </p>
            <button
              className="button button--large -dosis"
              onClick={onGetStartedClick}
            >
              Get started
            </button>
          </div>
        )}
      </div>
      {image()}
    </>
  );

  return (
    <section id="get-started" className="get-started" ref={wrapper}>
      {posts.length > 0 ? Results : Form}
    </section>
  );
}

export default React.memo(GetStarted);
