import React from "react";
import Figure from "../components/blocks/figure";
import SimpleBlocks from "../components/blocks/simple";

const Intro = props => {
  const { title, subtitle, figure, heroFigure, linkUrl, linkTitle } =
    props.intro;

  const figureOptions = {
    width: 1000,
    aspectRatio: 16 / 9,
  };
  const heroFigureOptions = {
    width: 1400,
    aspectRatio: 2 / 1,
  };
  const copy = props.intro._rawCopy;

  let _title = title;
  if (title === "A different world") {
    // Hack to make second line of speech bubble larger for the particular
    // title they are launching with
    _title = (
      <span className="title--speech-bubble">
        A different <span>world</span>
      </span>
    );
  } else {
    _title = <span className="title--speech-bubble">{title}</span>;
  }

  return (
    <section className="intro">
      <div className="container">
        <div className="intro-hero-image">
          <Figure figure={heroFigure} options={heroFigureOptions}></Figure>
        </div>
        <div className="intro-wrapper">
          <div className="intro-copy">
            {_title}
            <h2 className="subtitle" id="intro">
              {subtitle}
            </h2>
            {copy && <SimpleBlocks data={copy} className="copy" />}
            <div className="button-container">
              <a
                className="button button--large button--secondary button--block"
                href={linkUrl}
              >
                {linkTitle}
              </a>
            </div>
          </div>
          <div className="intro-image">
            <Figure figure={figure} options={figureOptions}></Figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
