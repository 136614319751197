import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import HeroHome from "../components/hero-home";
import Intro from "../components/intro";
import ReadMore from "../components/read-more";
import PostSpotlight from "../components/posts/spotlight";
import PostsList from "../components/posts/list";
import GetStarted from "../components/get-started";

export const query = graphql`
  query HomePageQuery {
    page: allSanityPageHome {
      nodes {
        title
        subtitle
        intro {
          title
          subtitle
          linkUrl
          linkTitle
          _rawCopy(resolveReferences: { maxDepth: 8 })
          figure {
            caption
            source
            image {
              asset {
                _id
                altText
                metadata {
                  lqip
                  dimensions {
                    width
                    height
                    aspectRatio
                  }
                }
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
          heroFigure {
            image {
              asset {
                _id
                altText
                metadata {
                  lqip
                  dimensions {
                    width
                    height
                    aspectRatio
                  }
                }
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
        }
        searchSuggestions
      }
    }
    livedExperience: allSanityArticle(
      filter: {
        slug: {}
        tags: {
          elemMatch: { slug: { current: { eq: "stories-lived-experience" } } }
        }
      }
      sort: { fields: publishDate, order: DESC }
      limit: 1
    ) {
      nodes {
        ...articleTileFields
      }
    }
    articles: allSanityArticle(
      sort: { fields: publishDate, order: DESC }
      limit: 4
    ) {
      nodes {
        ...articleTileFields
      }
    }
    resources: allSanityResource(
      sort: { fields: publishDate, order: DESC }
      limit: 4
    ) {
      nodes {
        ...resourceTileFields
      }
    }
    events: allSanityEvent(
      sort: { fields: eventDates___start, order: ASC }
      filter: { isFuture: { eq: true } }
      limit: 4
    ) {
      nodes {
        ...eventTileFields
      }
    }
  }
`;

const Index = ({ data }) => {
  const page = data.page.nodes[0];
  const events = data.events.nodes;
  const livedExperience =
    data.livedExperience.nodes.length && data.livedExperience.nodes[0];
  const articles = data.articles.nodes
    .filter(article => article._id !== livedExperience._id)
    .slice(0, 3);

  return (
    <Layout className="home" backgroundColor="#201c50" navSearch={false}>
      <SEO title="Flying Colours" />
      <HeroHome
        title={page.title}
        description={page.subtitle}
        searchSuggestions={page.searchSuggestions}
      ></HeroHome>
      <Intro intro={page.intro}></Intro>

      <div className="main">
        <div className="container">
          <GetStarted />
          {livedExperience && (
            <section className="spotlight">
              <h2 className="title">Spotlight on</h2>
              <p>
                Stories from parents with a lived experience and articles to
                help you navigate a different day.
              </p>
              <PostSpotlight post={livedExperience}></PostSpotlight>
              <Link
                className="button button--large button--secondary button--shadow"
                to="/content-finder/?tag=stories-lived-experience"
              >
                See more stories
              </Link>
            </section>
          )}
          {articles.length > 0 && (
            <section className="articles posts-container">
              <h3 className="title">In other news</h3>

              <PostsList
                posts={articles}
                includeFeatured={false}
                condensed={false}
                carousel={true}
              ></PostsList>
              <ReadMore
                text="View all the latest news"
                link="/content-finder/?type=article"
              ></ReadMore>
            </section>
          )}

          {data.resources.nodes.length > 0 && (
            <section className="resources posts-container">
              <h2 className="title">
                Support your journey with helpful resources
              </h2>

              <PostsList
                posts={data.resources.nodes}
                includeFeatured={true}
                condensed={true}
              ></PostsList>

              <ReadMore
                text="View more resources"
                link="/content-finder/?type=resource"
              ></ReadMore>
            </section>
          )}

          {events.length > 0 && (
            <section className="events posts-container">
              <h2 className="title">Upcoming events</h2>

              <PostsList
                posts={events}
                includeFeatured={true}
                condensed={false}
              ></PostsList>
              <ReadMore
                text="View more events"
                link="/link-up/"
              ></ReadMore>
            </section>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Index;
