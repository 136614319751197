import React from "react";
import { Link } from "gatsby";

const ReadMore = ({ text, link }) => {
  return (
    <Link
      to={link}
      className="button button--large button--secondary button--shadow"
    >
      {text}
    </Link>
  );
};

export default ReadMore;
