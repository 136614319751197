import React from "react";
import Search from "./search";

const HeroHome = ({ title, description, searchSuggestions = [] }) => {
  return (
    <header className="hero hero--home">
      <div className="container">
        <h1 className="h0">{title}</h1>
        <p>{description}</p>
        <Search searchSuggestions={searchSuggestions} />
      </div>
    </header>
  );
};

export default HeroHome;
