import React from "react";
import PostTile from "./tile";

const PostsList = ({
  posts,
  includeFeatured = false,
  condensed = true,
  carousel = false,
  fromLinkUp
}) => {
  let className = condensed ? "posts-condensed" : "posts-grid";

  if (includeFeatured) {
    className += " posts--featured";
  }

  if (carousel) {
    className += " posts--carousel";
  }
  return (
    <ul className={`posts ${className}`}>
      {posts.length &&
        posts.map((post, i) => {
          const featured = includeFeatured && i === 0;
          return <PostTile post={post} featured={featured} key={i} fromLinkUp={fromLinkUp}></PostTile>;
        })}
    </ul>
  );
};

export default PostsList;
